export const MAX_WIDTH_DESKTOP = 2000;

export const VIDEO_SMALL = 768;
export const VIDEO_MEDIUM = 1280;
export const VIDEO_LARGE = 1600;

// 3d Layers
export const LAYER_FG = 3;
export const LAYER_UI = 4;
export const LAYER_BG = 5;
export const LAYER_CARD_INNER = 6;

// Colors
export const PALETTE_THEME = [
    ['#000000', '#b676cf', '#0a44df'], // default
    ['#0b5b81', '#2d8b58', '#edd500'], // low-tier
    ['#c5b3d2', '#0a6ff7', '#042383'], // mid-tier
    ['#e72e3c', '#cc8418', '#449a7a'], // high-tier
    ['#25293e', '#4c5688', '#c6ebe8'], // super-rare
];

// Scene reveals
export const BOX_DUR = 2;
export const PACK_DUR = 2;
export const CARDS_DUR = 2;
export const LIGHTS_DOWN_REVEAL_START = 4;
export const LIGHTS_DOWN_DUR = 10;

export const COMPLETE_DUR = 1.5;

// Reveal animations
export const IN_DUR = 1;
export const PAUSE_DUR = 0.75;
export const OUT_DUR = 1;
export const TOTAL_DUR = IN_DUR + PAUSE_DUR + OUT_DUR;
export const OUT_START = IN_DUR + PAUSE_DUR;
export const IN_EASE = 'power3.out';
export const OUT_EASE = 'power3.in';

export const REVEAL = {
    [IN_DUR]: IN_DUR,
    [PAUSE_DUR]: PAUSE_DUR,
    [OUT_DUR]: OUT_DUR,
    [TOTAL_DUR]: TOTAL_DUR,
    [IN_EASE]: IN_EASE,
    [OUT_EASE]: OUT_EASE,
    [OUT_START]: OUT_START,
};
