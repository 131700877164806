// Messages from the user to the parent
export const UserReady = 'UserReady'; // Message sent telling parent we are ready to receive messages
export const PreloaderComplete = 'PreloaderComplete'; // Message sent telling parent the box has been revealed
export const BoxRevealComplete = 'BoxRevealComplete'; // Message sent telling parent the box has been revealed
export const PackRevealComplete = 'PackRevealComplete'; // Message sent telling parent the pack has been revealed
export const CardRevealComplete = 'CardRevealComplete'; // Message sent telling parent the card has been revealed

// Messages from the parent to the user
export const Setup = 'Setup'; // Incoming message with setup information including user, box, step and current card.
export const UnlockAudio = 'UnlockAudio'; // Incoming message to unlock the audio
export const UpdateStep = 'UpdateStep'; // Incoming message to indicate the current step in the process. Should be one of steps.js.
export const UpdateCard = 'UpdateCard'; // Incoming message to update the state of the card. Could be index, revealed, rotation, flip, etc. Should include all the information
export const Complete = 'Complete'; // Incoming message to update the state of the card. Could be index, revealed, rotation, flip, etc. Should include all the information
